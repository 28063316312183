import PublicMethod from "@/utils/publicFun.js";
import {httpService} from "../services/httpService.js";

export default {
    data() {
        return {
            interval: null,
            countDown: 120,
            gettingCode: false,
            phone: "",
            regCode: "",
            sessionId: "",
        };
    },
    methods: {
        // 初始化，取消倒计时
        initSetInterval() {
            window.clearInterval(this.interval);
            this.gettingCode = false;
            this.countDown = 120;
        },
        // 倒计时
        counter() {
            this.interval = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.initSetInterval();
                }
            }, 1000);
            this.gettingCode = true;
        },
        // 获取验证码
        async sendCode() {
            let result = PublicMethod.validatorPhone(this.phone);
            if (!result.status) {
                this.$itoast(result.msg);
                return;
            }
            this.initSetInterval();
            this.counter();

            let params = {
                sessionId: this.sessionId,
                mobiles: this.phone,
                scope: 0,
                sign: 1,
                product: "会引擎",
            };

            let res = await httpService.sendCaptcha(params);
            if (res.success) {
                this.$itoast("验证码发送成功,请注意查收！");
                this.sessionId = res.content.sessionId;
            } else if (res.code) {
                this.$idialog.alert({
                    title: "提示",
                    message: res.msg,
                });
            }
        },
    },
};
