var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bind-phone" },
    [
      _c("i-header", { on: { "back-event": _vm.goBack } }),
      _c("div", { staticClass: "sub-title" }, [_vm._v("绑定手机")]),
      _c(
        "div",
        { staticClass: "form-content" },
        [
          _c("i-input", {
            attrs: { title: "手机号码", placeholder: "请输入手机号码" },
            model: {
              value: _vm.phone,
              callback: function ($$v) {
                _vm.phone = $$v
              },
              expression: "phone",
            },
          }),
          _c(
            "div",
            { staticClass: "send-code" },
            [
              _c("i-input", {
                staticStyle: { width: "70%" },
                attrs: { title: "验证码", max: 6, placeholder: "请输入验证码" },
                model: {
                  value: _vm.regCode,
                  callback: function ($$v) {
                    _vm.regCode = $$v
                  },
                  expression: "regCode",
                },
              }),
              _c(
                "i-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    plain: "",
                    size: "small",
                    disabled: _vm.gettingCode,
                  },
                  on: { click: _vm.sendCode },
                },
                [
                  _vm.gettingCode
                    ? _c("span", [_vm._v(_vm._s(_vm.countDown) + "s")])
                    : _c("span", [_vm._v("发送验证码")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "submit-btn" },
        [
          _c(
            "i-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "large",
                loading: _vm.isLoading,
                "loading-text": "确 定",
                disabled: !_vm.isDisabled,
              },
              on: { click: _vm.setMobileFactorByUserId },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }