<template>
    <div class="bind-phone">
        <i-header @back-event="goBack"></i-header>
        <div class="sub-title">绑定手机</div>

        <div class="form-content">
            <i-input
                title="手机号码"
                v-model="phone"
                placeholder="请输入手机号码"
            />
            <div class="send-code">
                <i-input
                    title="验证码"
                    style="width: 70%"
                    :max="6"
                    v-model="regCode"
                    placeholder="请输入验证码"
                />
                <i-button
                    type="primary"
                    round
                    plain
                    size="small"
                    :disabled="gettingCode"
                    @click="sendCode"
                >
                    <span v-if="gettingCode">{{ countDown }}s</span>
                    <span v-else>发送验证码</span>
                </i-button>
            </div>
        </div>

        <div class="submit-btn">
            <i-button
                type="primary"
                round
                size="large"
                :loading="isLoading"
                loading-text="确 定"
                :disabled="!isDisabled"
                @click="setMobileFactorByUserId"
            >
                确 定
            </i-button>
        </div>
    </div>
</template>

<script>
import sendCode from "../utils/sendCode";

export default {
    extends: sendCode,
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        isDisabled() {
            return (
                this.phone && (this.password || this.regCode)
            );
        },
    },
    methods: {
        // 绑定手机号
        async setMobileFactorByUserId() {
            let result = this.$publicFun.validatorPhone(this.phone);
            if (!result.status) {
                this.$itoast(result.msg);
                return;
            }
            if (!this.sessionId) {
                this.$itoast("验证码不匹配，请重新获取验证码");
            }
            let params = {
                UserId: this.$smartStorage.get("userMsg")?.userId || '',
                SessionId: this.sessionId,
                Value: this.phone,
                Captcha: this.regCode,
            };
            this.isLoading = true;
            let res = await this.$service.setMobileFactorByUserId(params);
            this.isLoading = false;
            if (res.success) {
                this.$itoast("绑定手机号码成功！");
                this.toHomePage()
            } else if (res.code) {
                this.$itoast(res.msg);
            }
        },
        // 返回按钮
        goBack() {
        },
        toHomePage() {
            this.$router.push({
                path: '/'
            })
        }
    },
};
</script>

<style scoped lang="less">
.sub-title {
    margin: 0.2rem auto;
    padding: 0 0.25rem;
    font-size: 0.24rem;
}
.submit-btn {
    margin-top: 0.3rem;
    margin-left: 5%;
    width: 90%;
}
.form-content {
    padding: 0 0.26rem;
    .send-code {
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // padding-right: 0.25rem;
        button {
            width: 50%;
        }
    }
}
</style>
